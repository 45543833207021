module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true,"dsn":"https://8f2781e64bb04854a5c0e2370b7c5849@sentry.io/1454229","release":"0.1.4","environment":"production","debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Diet & Training by Ann","short_name":"Diet by Ann","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"./src/images/meta/icon-310.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3d84466329c2a190ff38da5d480849b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
